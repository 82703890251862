/* eslint-disable max-len */
import IntegrationData from "../../../../../config/integrations";
// import feature1 from "../../../../assets/images/nl-images/subscription-1.png";
// import feature5 from "../../../../assets/images/nl-images/subscription-3.png";
// import feature2 from "../../../../assets/images/nl-images/subscription-4.png";
// import feature3 from "../../../../assets/images/nl-images/subscription-5.png";
// import feature4 from "../../../../assets/images/nl-images/subscription-6.png";
import {
  BIRUBAL_AD_FREE_SAY_NO_TO_ADS,
  BIRUBAL_REFUSING_BRIBE_SAY_NO_TO_MONEY,
  BIRUBALA_GIFTING,
  BIRUBALA_JOURNALIST_ASKING_QUESTIONS,
  TEAM_AVATARS
} from "../../../image-constants";

const IS_HINDI = !!(global.location && global.location.host.includes("hindi"));
// const IS_HINDI = true;
const IS_STAGING = IntegrationData().isAccesstypeStaging;

export const BLOCKS = !IS_HINDI
  ? [
      {
        groupId: IS_STAGING ? 759 : 1370,
        planId: IS_STAGING ? 1367 : 4671,
        groupName: "डिसरप्टर",
        planName: "मासिक",
        price: "299",
        originalPrice: null,
        benefits: [
          "एनएल टीम से सीधे मुलाकात का मौका",
          "पेवॉल कंटेंट की एक्सेस",
          "सब्सक्राइबर्स के लिए विशेष ऑफर्स"
        ],
        img: "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg"
      },
      {
        groupId: IS_STAGING ? 759 : 1370,
        planId: IS_STAGING ? 1369 : 4672,
        groupName: "डिसरप्टर",
        planName: "6 महीने",
        price: "1499",
        originalPrice: "1800",
        benefits: [
          "एनएल टीम से सीधे मुलाकात का मौका",
          "पेवॉल कंटेंट की एक्सेस",
          "सब्सक्राइबर्स के लिए विशेष ऑफर्स"
        ],
        img: "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg"
      },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 1367 : 338,
        groupName: "Disruptor",
        planName: "Monthly",
        price: "300",
        originalPrice: null,
        benefits: [
          "Paywall stories",
          // "Subscriber-only Discord server",
          "Meet-ups and virtual hangouts: NL Recess, NL Chatbox",
          "Subscriber-only events, including The Media Rumble"
        ],
        img: "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg"
      },
      // {
      //   groupId: 114,
      //   planId: 2632,
      //   groupName: "Disruptor",
      //   planName: "Quarterly",
      //   price: "900",
      //   originalPrice: null,
      //   benefits: [
      //     "Paywall stories",
      //     "Subscriber-only Discord server",
      //     "Meetups and virtual hangouts: NL Recess, NL Chatbox",
      //     "Subscriber-only events, including The Media Rumble"
      //   ],
      //   img:
      //     "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg"
      // },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 1369 : 340,
        groupName: "Disruptor",
        planName: "Annual",
        price: "3000",
        originalPrice: "3600",
        benefits: [
          "Paywall stories",
          // "Subscriber-only Discord server",
          "Meet-ups and virtual hangouts: NL Recess, NL Chatbox",
          "Subscriber-only events, including The Media Rumble"
        ],
        plus: [
          "Priority access to new shows and products",
          "Laptop stickers",
          "Fridge magnets"
          // "You save ₹600"
        ],
        img: "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 1378 : 343,
        groupName: "Game Changer",
        planName: "Annual",
        price: "10000",
        originalPrice: "12000",
        benefits: ["All Disruptor benefits including merch"],
        plus: [
          "Access paywall podcast in Apple and Google Podcast",
          "Five one-month subscriptions to gift",
          "Pay To Keep News Free tote bag and car stickers",
          "NL Baithak: Quarterly Zoom call with team leads at Newslaundry",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"

          // "You save ₹2000"
        ],
        img: "https://images.assettype.com/newslaundry/2020-10/f503dc8c-2cc1-4990-8431-8e132bc4827d/02_Subscription_Page_sub_level_icons_a.png"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 1376 : 341,
        groupName: "Game Changer",
        planName: "Monthly",
        price: "1000",
        benefits: ["All Disruptor benefits including merch"],
        plus: [
          "Access paywall podcast in Apple and Google Podcast",
          "NL Baithak: Quarterly Zoom call with team leads at Newslaundry",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"

          // "You save ₹2000"
        ],
        img: "https://images.assettype.com/newslaundry/2020-10/f503dc8c-2cc1-4990-8431-8e132bc4827d/02_Subscription_Page_sub_level_icons_a.png"
      },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 2029 : 2033,
        groupName: "Disruptor",
        planName: "Disruptor - Student",
        price: "1,500",
        originalPrice: "3,000",
        benefits: [
          "Paywall stories",
          // "Subscriber-only Discord server",
          "Meet-ups and virtual hangouts: NL Recess, NL Chatbox",
          "Subscriber-only events, including The Media Rumble"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 4453 : 4474,
        groupName: "Disruptor Monthly Joint Subscription",
        planName: "Joint Subscription Monthly",
        price: "500",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute",
          "Priority access to all meet ups and events, including The Media Rumble ",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 4455 : 4471,
        groupName: "Disruptor Annual Joint Subscription",
        planName: "Joint Subscription Annual",
        price: "4999",
        originalPrice: "6000",
        benefits: [
          " Paywall stories on both Newslaundry and The News Minute",
          "Stronger together merch – Fridge magnets and laptop stickers",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 4457 : 4478,
        groupName: "Game Changer Monthly Joint Subscription",
        planName: "Joint Subscription Monthly",
        price: "1599",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute ",
          "RSS feed of all paywall podcasts",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox, NL Baithak and monthly editorial call with the team",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 4460 : 4476,
        groupName: "Game Changer Annual Joint Subscription",
        planName: "Joint Subscription Annual",
        price: "15,999",
        originalPrice: "19,000",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute ",
          "RSS feed of all paywall podcasts",
          "Stronger together merch – Fridge magnets, laptop stickers, mug",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      // below objects are for joint overseas USD plans
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 4454 : 4473,
        groupName: "Disruptor Monthly Joint Subscription",
        planName: "Joint Subscription Monthly",
        price: "500",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute",
          "Priority access to all meet ups and events, including The Media Rumble ",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 4456 : 4472,
        groupName: "Disruptor Annual Joint Subscription",
        planName: "Joint Subscription Annual",
        price: "4999",
        originalPrice: "6000",
        benefits: [
          " Paywall stories on both Newslaundry and The News Minute",
          "Stronger together merch – Fridge magnets and laptop stickers",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 4458 : 4477,
        groupName: "Game Changer Monthly Joint Subscription",
        planName: "Joint Subscription Monthly",
        price: "1599",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute ",
          "RSS feed of all paywall podcasts",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox, NL Baithak and monthly editorial call with the team",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 4460 : 4475,
        groupName: "Game Changer Annual Joint Subscription",
        planName: "Joint Subscription Annual",
        price: "15,999",
        originalPrice: "19,000",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute ",
          "RSS feed of all paywall podcasts",
          "Stronger together merch – Fridge magnets, laptop stickers, mug",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      }
    ]
  : [
      // {
      //   groupId: IS_STAGING ? 1352 : 1370,
      //   planId: IS_STAGING ? 4071 : 4060,
      //   groupName: "डिसरप्टर",
      //   planName: "3 महीने",
      //   price: "499",
      //   originalPrice: "900",
      //   benefits: [
      //     "एनएल टीम से सीधे मुलाकात का मौका",
      //     "पेवॉल कंटेंट की एक्सेस",
      //     "सबस्क्राइबर्स के लिए विशेष ऑफर्स",
      //   ],
      //   img:
      //     "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg",
      // },
      // {
      //   groupId: IS_STAGING ? 1352 : 1370,
      //   planId: IS_STAGING ? 4072 : 4061,
      //   groupName: "डिसरप्टर",
      //   planName: "6 महीने",
      //   price: "999",
      //   originalPrice: "1800",
      //   benefits: [
      //     "एनएल टीम से सीधे मुलाकात का मौका",
      //     "पेवॉल कंटेंट की एक्सेस",
      //     "सबस्क्राइबर्स के लिए विशेष ऑफर्स",
      //   ],
      //   // plus: [],
      //   img:
      //     "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg",
      // },
      {
        groupId: IS_STAGING ? 759 : 1370,
        planId: IS_STAGING ? 1367 : 4671,
        groupName: "डिसरप्टर",
        planName: "मासिक",
        price: "299",
        originalPrice: null,
        benefits: [
          // "एनएल टीम से सीधे मुलाकात का मौका",
          // "पेवॉल कंटेंट की एक्सेस",
          // "सब्सक्राइबर्स के लिए विशेष ऑफर्स",
          "पेवॉल्ड कंटेंट: विशेष न्यूज़ रिपोर्ट्स, प्रीमियम शोज़ जैसे एनएल चर्चा, हफ़्ता, पेवॉल रिपोर्ट्स और इंटरव्यूज़",
          "न्यूज़लॉन्ड्री टीम से मिलें: एनएल चैटबॉक्स, सब्सक्राइबर्स का टीम के साथ मासिक संवाद",
          "केवल सब्सक्राइबर्स के लिए व्हाट्सएप कम्युनिटी: आप हमारे व्हाट्सएप कम्युनिटी चैनलों से जुड़ सकते हैं",
          "न्यूज़लॉन्ड्री इवेंट्स: एनएल रीसेस, द मीडिया रम्बल, वेबिनार्स और सब्सक्राइबर मीट-अप्स आदि"
        ],
        img: "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg"
      },
      {
        groupId: IS_STAGING ? 759 : 1370,
        planId: IS_STAGING ? 1369 : 4672,
        groupName: "डिसरप्टर",
        planName: "6 महीने",
        price: "1499",
        originalPrice: "1800",
        benefits: [
          // "एनएल टीम से सीधे मुलाकात का मौका",
          // "पेवॉल कंटेंट की एक्सेस",
          // "सब्सक्राइबर्स के लिए विशेष ऑफर्स",
          "पेवॉल्ड कंटेंट: विशेष न्यूज़ रिपोर्ट्स, प्रीमियम शोज़ जैसे एनएल चर्चा, हफ़्ता, पेवॉल रिपोर्ट्स और इंटरव्यूज़",
          "न्यूज़लॉन्ड्री टीम से मिलें: एनएल चैटबॉक्स, सब्सक्राइबर्स का टीम के साथ मासिक संवाद",
          "केवल सब्सक्राइबर्स के लिए व्हाट्सएप कम्युनिटी: आप हमारे व्हाट्सएप कम्युनिटी चैनलों से जुड़ सकते हैं",
          "न्यूज़लॉन्ड्री इवेंट्स: एनएल रीसेस, द मीडिया रम्बल, वेबिनार्स और सब्सक्राइबर मीट-अप्स आदि"
        ],
        img: "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg"
      },
      // {
      //   groupId: IS_STAGING ? 759 : 114,
      //   planId: IS_STAGING ? 1369 : 340,
      //   groupName: "डिसरप्टर",
      //   planName: "वार्षिक",
      //   price: "3000",
      //   originalPrice: "3600",
      //   benefits: [
      //     "एनएल टीम से सीधे मुलाकात का मौका",
      //     "पेवॉल कंटेंट की एक्सेस",
      //     "सब्सक्राइबर्स के लिए विशेष ऑफर्स",
      //     "नए शो और उत्पादों की सबसे पहले एक्सेस",
      //     "लैपटॉप स्टिकर",
      //     "फ्रिज मैग्नेट",
      //     "आपके 600 रुपए बचेंगे",
      //   ],
      //   img:
      //     "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg",
      // },
      // {
      //   groupId: IS_STAGING ? 760 : 113,
      //   planId: IS_STAGING ? 1378 : 343,
      //   groupName: "गेम चेंजर",
      //   planName: "वार्षिक",
      //   price: "10000",
      //   originalPrice: "12000",
      //   benefits: ["डिसरप्टर के सभी लाभ"],
      //   plus: [
      //     "एप्पल और गूगल पॉडकास्ट में पेवॉल पॉडकास्ट एक्सेस",
      //     "किन्हीं पांच साथियों को एक महीने का सब्सक्रिप्शन गिफ्ट करने की सुविधा",
      //     "फ्री Pay To Keep News टोट बैग और कार स्टीकर",
      //     "एनएल बैठक: न्यूज़लॉन्ड्री में टीम लीड्स के साथ ज़ूम कॉल",
      //   ],
      //   img:
      //     "https://images.assettype.com/newslaundry/2020-10/f503dc8c-2cc1-4990-8431-8e132bc4827d/02_Subscription_Page_sub_level_icons_a.png",
      // },
      // {
      //   groupId: IS_STAGING ? 760 : 113,
      //   planId: IS_STAGING ? 1376 : 341,
      //   groupName: "गेम चेंजर",
      //   planName: "मासिक",
      //   price: "1000",
      //   benefits: [
      //     "एप्पल और गूगल पॉडकास्ट में पेवॉल पॉडकास्ट एक्सेस",
      //     "एनएल बैठक: न्यूज़लॉन्ड्री में टीम लीड्स के साथ ज़ूम कॉल",
      //     "एनएल टीम से सीधे मुलाकात का मौका",
      //     "पेवॉल कंटेंट की एक्सेस",
      //     "सब्सक्राइबर्स के लिए विशेष ऑफर्स",
      //   ],
      //   img:
      //     "https://images.assettype.com/newslaundry/2020-10/f503dc8c-2cc1-4990-8431-8e132bc4827d/02_Subscription_Page_sub_level_icons_a.png",
      // },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 4453 : 4474,
        groupName: "Disruptor Monthly Joint Subscription",
        planName: "Joint Subscription Monthly",
        price: "500",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute",
          "Priority access to all meet ups and events, including The Media Rumble ",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 4455 : 4471,
        groupName: "Disruptor Annual Joint Subscription",
        planName: "Joint Subscription Annual",
        price: "4999",
        originalPrice: "6000",
        benefits: [
          " Paywall stories on both Newslaundry and The News Minute",
          "Stronger together merch – Fridge magnets and laptop stickers",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 4457 : 4478,
        groupName: "Game Changer Monthly Joint Subscription",
        planName: "Joint Subscription Monthly",
        price: "1599",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute ",
          "RSS feed of all paywall podcasts",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox, NL Baithak and monthly editorial call with the team",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 4460 : 4476,
        groupName: "Game Changer Annual Joint Subscription",
        planName: "Joint Subscription Annual",
        price: "15,999",
        originalPrice: "19,000",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute ",
          "RSS feed of all paywall podcasts",
          "Stronger together merch – Fridge magnets, laptop stickers, mug",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 4453 : 4473,
        groupName: "Disruptor Monthly Joint Subscription",
        planName: "Joint Subscription Monthly",
        price: "500",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute",
          "Priority access to all meet ups and events, including The Media Rumble ",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 4455 : 4472,
        groupName: "Disruptor Annual Joint Subscription",
        planName: "Joint Subscription Annual",
        price: "4999",
        originalPrice: "6000",
        benefits: [
          " Paywall stories on both Newslaundry and The News Minute",
          "Stronger together merch – Fridge magnets and laptop stickers",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 4457 : 4477,
        groupName: "Game Changer Monthly Joint Subscription",
        planName: "Joint Subscription Monthly",
        price: "1599",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute ",
          "RSS feed of all paywall podcasts",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox, NL Baithak and monthly editorial call with the team",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      },
      {
        groupId: IS_STAGING ? 760 : 113,
        planId: IS_STAGING ? 4460 : 4475,
        groupName: "Game Changer Annual Joint Subscription",
        planName: "Joint Subscription Annual",
        price: "15,999",
        originalPrice: "19,000",
        benefits: [
          "Paywall stories on both Newslaundry and The News Minute ",
          "RSS feed of all paywall podcasts",
          "Stronger together merch – Fridge magnets, laptop stickers, mug",
          "Priority access to all meet ups and events, including The Media Rumble",
          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team",
          "Access to Paper Trail -- a fortnightly e-paper with the best of Newslaundy and The News Minute delivered to your inbox.<a href='https://images.assettype.com/newslaundry/2024-02/e7d72a73-038f-4c77-bec9-0bfdccdbf610/E_PAPER_anniversary_edition.pdf?utm_source=NL+Automated+Subscribers&utm_campaign=1f95f3d252-EMAIL_CAMPAIGN_2023_06_09_01_25_COPY_01&utm_medium=email&utm_term=0_-d2c0fc2dfc-%5BLIST_EMAIL_ID%5D'><i>Preview the first edition.</i></a>"
        ],
        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
      }
    ];

export const GIFTBLOCKS = !IS_HINDI
  ? [
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 1369 : 340,
        groupName: "Disruptor",
        planName: "Annual",
        price: "3000",
        originalPrice: "3600",
        benefits: [
          "Paywall stories",
          // "Subscriber-only Discord server",
          "Meet-ups and virtual hangouts: NL Recess, NL Chatbox",
          "Subscriber-only events, including The Media Rumble"
        ],
        plus: [
          "Priority access to new shows and products",
          "Laptop stickers",
          "Fridge magnets"
          // "You save ₹600"
        ],
        img: "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg"
      }
    ]
  : [
      {
        groupId: IS_STAGING ? 759 : 114,
        planId: IS_STAGING ? 1367 : 340,
        groupName: "डिसरप्टर",
        planName: "वार्षिक",
        price: "3000",
        originalPrice: "3600",
        benefits: [
          "वह लेख और ख़बरें जो पेवॉल के पीछे हैं",
          "डिस्कॉर्ड सर्वर केवल सब्सक्राइबर्स के लिए",
          "न्यूज़लॉन्ड्री टीम के साथ मीटिंग और वर्चुअल हैंगआउट",
          "सब्सक्राइबर्स के लिए कार्यक्रम, जिनमें द मीडिया रंबल भी शामिल है"
        ],
        // plus: ["लैपटॉप स्टिकर", "फ्रिज मैग्नेट", "आपके 600 रुपए बचेंगे"],
        plus: ["लैपटॉप स्टिकर", "फ्रिज मैग्नेट"],
        img: "https://thumbor-stg.assettype.com/newslaundry/2020-09/9e807050-f157-4a10-b9ad-3d33573103a1/02_Subscription_Page____sub_level_icons_b.jpg"
      }
    ];

export const TEXT = !IS_HINDI
  ? {
      subscriptionTitle: "Pay to keep news free",
      subscriptionDescription:
        "We serve you, not the advertisers. We believe those who pay for news, determine its direction. You decide. Reach out to us at subscription@newslaundry.com for any issues related to your subscription.",
      giftTitle: "Gift a subscription",
      giftDescription:
        "Share a year’s worth of ground reports, podcasts and videos with a fellow reader.",
      studentTitle: "Student Subscription",
      studentDescription:
        "Are you a student? Verify your student ID and get a 50% discount on Disruptor annual subscription.",
      senaTitle: "NL Sena",
      senaDescriptiion:
        "You know the stories and issues that either get buried or are ignored by mainstream media? WIth your support, we can do those stories",
      groupTitle: "Group Subscription",
      groupDescription:
        "Are you a group or an institution looking to get a Newslaundry subscription? Click here to get group subscription discounted offers.",
      angelTitle: "Angel Subscription",
      angelDescription:
        "Be an angel for independent media. We believe those who pay for news determine its direction. Independent media needs thousands of readers like you to step up and show support."
    }
  : {
      subscriptionTitle: "मेरे खर्च पर आज़ाद हैं ख़बरें",
      subscriptionDescription:
        "हम आपकी सेवा करते हैं, विज्ञापनदाताओं की नहीं। हमारा मानना ​​है कि जो लोग समाचार के लिए भुगतान करते हैं, वे ही इसकी दिशा निर्धारित करते हैं। आप तय करें। अपनी सदस्यता से संबंधित किसी भी समस्या के लिए subscription@newslaundry.com पर हमसे संपर्क करें।",
      giftTitle: "सबस्क्रिप्शन गिफ्ट करें",
      giftDescription:
        "अपने एक साथी पाठक को आप एक साल के लिए न्यूज़लॉन्ड्री की ग्राउंड रिपोर्ट और पॉडकास्ट को साझा कर सकते हैं. तो देर किस बात की, न्यूज़लॉन्ड्री सब्सक्रिप्शन गिफ्ट करें.",
      studentTitle: "छात्र सब्सक्रिप्शन",
      studentDescription:
        "हम नहीं चाहते कि छात्र बहुमूल्य सूचनाओं से वंचित रहें. इसलिए हम अपने ग्राहकों की मदद से छात्रों को मुफ्त सब्सक्रिप्शन देते हैं.",
      senaTitle: "एनएल सेना",
      senaDescriptiion:
        "इसके जरिए आप उन ख़बरों और मुद्दों की तह में जाते हैं जिन्हें मुख्यधारा का मीडिया दबा देता है या नजरअंदाज कर देता है? आपके सहयोग से हम उन मुद्दों को उठाते हैं",
      groupTitle: "Group Subscription",
      groupDescription:
        "Are you a group or an institution looking to get a Newslaundry subscription? Click here to get group subscription discounted offers.",
      angelTitle: "Angel Subscription",
      angelDescription:
        "Be an angel for independent media. We believe those who pay for news determine its direction. Independent media needs thousands of readers like you to step up and show support."
    };

export const FEATURES = !IS_HINDI
  ? [
      {
        imgSrc: BIRUBAL_REFUSING_BRIBE_SAY_NO_TO_MONEY,
        title: "Pay to keep news free",
        description:
          "Newslaundry makes news a service to the public, not to the advertisers. We believe those who make sustainable efforts, determine its direction. You decide."
      },
      {
        imgSrc: BIRUBALA_GIFTING,
        title: "Paywall Access",
        description:
          " Stories cost perseverance, time and resources. Your support can make this possible. In-depth reports, podcasts and interviews, exclusively for you."
      },
      {
        imgSrc: BIRUBAL_AD_FREE_SAY_NO_TO_ADS,
        title: "Ad Free",
        description:
          "Independent journalism is not possible until you pitch in. We have already seen what happens in ad-funded models – journalism takes a backseat and gets sacrificed at the altar of clicks and TRPs."
      },
      {
        imgSrc: BIRUBALA_JOURNALIST_ASKING_QUESTIONS,
        title: "Ground Reports",
        description:
          "Our reporters seek out the voices that matter, far from television studios and big cities. Help us to tell those stories – through text, audio and video – by powering our ground reportage."
      },
      {
        imgSrc: TEAM_AVATARS,
        title: "Engage with NL community",
        description:
          "Access to our subscriber-only exclusive events, including The Media Rumble, webinars and chats with the NL team: NL Recess, NL Chatbox." // and Discord Lives with reporters."
      }
    ]
  : [
      {
        imgSrc: BIRUBAL_REFUSING_BRIBE_SAY_NO_TO_MONEY,
        title: "ख़बरों के आज़ाद रखने के लिए खर्च करें",
        description:
          "न्यूज़लॉन्ड्री की ख़बरें विज्ञापनदाताओं की नहीं, बल्कि जनहित के लिए होती है. हम मानते हैं कि जो लगातार कोशिश करते हैं वही अंत में निर्णायक बनते हैं. यह तय करना आपके हाथ में है."
      },
      {
        imgSrc: BIRUBALA_GIFTING,
        title: "पेवॉल एक्सेस",
        description:
          "ख़बरों को आप तक लाने में काफी लगन, समय और संसाधन लगता है. यह आपके समर्थन से ही संभव है. विस्तृत रिपोर्ट, पॉडकास्ट और साक्षात्कार, खास आपके लिए."
      },
      {
        imgSrc: BIRUBAL_AD_FREE_SAY_NO_TO_ADS,
        title: "विज्ञापन मुक्त",
        description:
          "स्वतंत्र पत्रकारिता आपके हस्तक्षेप के बिना संभव नहीं है. हम पहले ही देख चुके हैं कि विज्ञापन-पोषित मीडिया कैसे काम करती है. पत्रकारिता पीछे छूट जाती है, क्लिक्स-हिट और टीआरपी उसकी जगह ले लेती है."
      },
      {
        imgSrc: BIRUBALA_JOURNALIST_ASKING_QUESTIONS,
        title: "ग्राउंड रिपोर्ट",
        description:
          "हमारे रिपोर्टर्स टेलीविजन स्टूडियो और बड़े शहरों से दूर उन आवाजों की तलाश करते हैं जो मायने रखती हैं. उन कहानियों को टेक्स्ट, ऑडियो और वीडियो के माध्यम से आप तक पहुंचाने में हमारी मदद करें -- हमारी ग्राउंड रिपोर्टिंग को सशक्त बनाएं"
      },
      {
        imgSrc: TEAM_AVATARS,
        title: "एनएल समुदाय के साथ जुड़ें",
        description:
          "यहां उपभोक्ताओं के लिए विशेष डिस्कॉर्ड सर्वर है और विशिष्ट कार्यक्रमों के माध्यम से हम उनसे जुड़े रहते हैं जैसे मीडिया रंबल, वेबिनार और एनएल टीम के साथ चैट: एनएल रिसेस, एनएल चैटबॉक्स और पत्रकारों के साथ डिस्कॉर्ड लाइव आदि."
      }
    ];
