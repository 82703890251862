import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
// import assetify from "@quintype/framework/assetify";
import { Link } from "@quintype/components";
import { CollectionName } from "../../atoms/collection-name";
import { CardImage } from "../../atoms/card-image";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import Carousel from "../../collection-templates/comics/carousel";
// import Arrow from "../../../../assets/images/arrow-right-black.svg";
// import groupImage from "../../../../assets/images/nl-images/videos-collection-page.png";
import CommonStoryCard from "../../molecules/common-story-card";
import CommonCollectionCard from "../../molecules/common-collection-card";
import {
  BIRUBAL_AND_BIRUBALA_ON_VIDEO_TV,
  COMPRESS_PARAMS_MEDIUM
} from "../../../image-constants";

import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";

import "./videos-section-page.m.css";

export const BigStory = ({ story }) => {
  const section = get(story, ["sections", "0"], {});
  const getLabel = get(
    story,
    ["metadata", "story-attributes", "storyattribute", 0],
    ""
  );
  return (
    <article>
      <div styleName="hero-story-wrapper">
        <div styleName="hero-image">
          <CardImage story={story} />
          {getLabel && <div styleName="ribbon">{getLabel}</div>}
        </div>
        <div styleName="content-wrapper">
          {/* <Link
            href={section["section-url"]}
            aria-label={`Click to go to section:${
              section["display-Name"] || section.name
            }`}
          >
            <div styleName="section" aria-hidden="true">
              <span
                style={{
                  position: "absolute",
                  width: "1px",
                  height: "1px",
                  margin: "-1px",
                  padding: "0",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  border: "0",
                }}
              >
                {section["display-Name"] || section.name}
              </span>
              {section["display-Name"] || section.name}
            </div>
          </Link> */}
          <Link
            href={section["section-url"]}
            aria-label={`Click to go to section:${
              section["display-Name"] || section.name
            }`}
          >
            <p styleName="section-badge">
              {section["display-Name"] || section.name}
            </p>
          </Link>
          <div styleName="headline">
            <Headline story={story} externalLink={true} />
          </div>
          <div styleName="sub-healine">
            <Subheadline story={story} />
          </div>
        </div>
      </div>
    </article>
  );
};

BigStory.propTypes = {
  story: PropTypes.object
};

const VideosSectionPage = props => {
  const collection = get(props, ["data", "collection"], {});
  const collections = collection.items.filter(
    collection => collection.type === "collection"
  );
  const firstCollectionStories =
    collection.items.filter(collection => collection.type === "story") || [];
  const secondCollection = collections[0];
  const collectionOfCollection =
    (secondCollection &&
      secondCollection.items.filter(
        collection => collection.type === "collection"
      )) ||
    [];
  const stories = firstCollectionStories.slice(1);
  // const coverImage = get(collection, [
  //   "metadata",
  //   "cover-image",
  //   "cover-image-url"
  // ]);
  const children = stories.map(item => (
    <div styleName="card-wrapper" key={item.story.id}>
      <CommonStoryCard story={item.story} showAuthorWithTime={false} />
    </div>
  ));

  if (collections.length < 1 && firstCollectionStories.length < 1) {
    return <div styleName="not-found">Stories not found...</div>;
  }

  return (
    <div>
      <header styleName="header" className="full-width-with-padding">
        <div styleName="text-wrapper">
          <CollectionName
            collection={collection}
            color="#fff"
            borderBottomColor="#fff"
          />
          <div styleName="summary">{get(collection, ["summary"], "")}</div>
        </div>
        <div styleName="img-wrapper">
          <img
            src={`${BIRUBAL_AND_BIRUBALA_ON_VIDEO_TV}${COMPRESS_PARAMS_MEDIUM}`}
            alt="section-image"
          />
        </div>
      </header>
      <div>
        {firstCollectionStories.slice(0, 1).map(story => (
          <BigStory story={story.story} key={story.id} />
        ))}
      </div>
      <div styleName="carousel">
        <Carousel
          carouselName={`carousel-${collection.slug}`}
          options={{
            type: `${stories.length <= 4 ? "slider" : "carousel"}`,
            perView: 3,
            gap: 30,
            breakpoints: {
              580: { perView: 1, gap: 32, peek: { before: 0, after: 80 } },
              992: { perView: 3, peek: { before: 0, after: 80 } }
            }
          }}
          totalItems={stories.length}
          showArrows={true}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => {
            return (
              currentSlide !== 0 && (
                <button
                  onClick={previousSlide}
                  className="slider-btn"
                  aria-label="Swipe left to view previous stories"
                >
                  {/* <img
                    src={`${assetify(Arrow)}`}
                    alt="left"
                    styleName="slider-arrow arrow-left"
                  /> */}
                  <FaCircleArrowLeft aria-hidden="true" />
                </button>
              )
            );
          }}
          renderCenterRightControls={({ nextSlide }) =>
            stories.length > 4 && (
              <button
                onClick={nextSlide}
                className="slider-btn"
                aria-label="Swipe right to view next stories"
              >
                {/* <img
                  src={`${assetify(Arrow)}`}
                  alt="right"
                  styleName="slider-arrow"
                /> */}
                <FaCircleArrowRight aria-hidden="true" />
              </button>
            )
          }
        >
          {children}
        </Carousel>
      </div>
      {collectionOfCollection.length > 0 && (
        <div styleName="sec-collection" className="full-width-with-padding">
          <CollectionName collection={secondCollection} />
          <div className="container" styleName="story-container">
            {/* <ul
              style={{
                "list-style-type": "none",
                margin: "0px",
                padding: "0px",
              }}
            > */}
            {collectionOfCollection.map(collection => (
              <CommonCollectionCard
                collection={collection}
                key={collection.id}
              />
            ))}
            {/* </ul> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default VideosSectionPage;
