import React, { useCallback, useEffect, useState } from "react";
import { object } from "prop-types";
// import assetify from "@quintype/framework/assetify";
import { connect } from "react-redux";
import get from "lodash/get";

import { CollectionName } from "../../atoms/collection-name";
// import NlPodcast from "../../../../assets/images/nlpodcast.png";

import { getGlobalPlayerRef } from "../../podcast-player/utils";
import { getAcastShows } from "../../helper/api";
import Loader from "../../atoms/loader";
import {
  BIRUBAL_READING_NEWS_PODCAST,
  COMPRESS_PARAMS_MEDIUM,
  COMPRESS_PARAMS_SMALL,
  PODCAST_SHOW_ANA,
  PODCAST_SHOW_CHARCHA,
  PODCAST_SHOW_HAFTA,
  PODCAST_SHOW_LTA,
  PODCAST_SHOW_NL_INTERVIEWS,
  PODCAST_SHOW_YBTH
} from "../../../image-constants";

import "./nl-podcast.m.css";

const showIds = [
  "5ebe751be3f889048a3fe4c0", // NL Conversations
  "5ec3d9497cef7479d2ef4798", // Hafta
  "5ef1c9ea878d6d0b1fb19ff2", // Let's Talk About
  "5ec24dba84c3fb5c3f29dd3a", // The Awful & Awesome Entertainment Wrap
  "62c525beec4ceb001246d19a", // Ye Bhi Theek Hai
  "5ec247bf1ad9f849b1e3c640" // NL Charcha
];
// "5ec24883bab33c11af39f705", // Daily Dose

const showCoversMappedToCMSImages = [
  {
    showId: "5ebe751be3f889048a3fe4c0", // NL Conversations,
    image: PODCAST_SHOW_NL_INTERVIEWS
  },
  {
    showId: "5ec3d9497cef7479d2ef4798", // Hafta
    image: PODCAST_SHOW_HAFTA
  },
  {
    showId: "5ef1c9ea878d6d0b1fb19ff2", // Let's Talk About
    image: PODCAST_SHOW_LTA
  },
  {
    showId: "5ec24dba84c3fb5c3f29dd3a", // The Awful & Awesome Entertainment Wrap
    image: PODCAST_SHOW_ANA
  },
  {
    showId: "62c525beec4ceb001246d19a", // Ye Bhi Theek Hai
    image: PODCAST_SHOW_YBTH
  },
  {
    showId: "5ec247bf1ad9f849b1e3c640", // NL Charcha
    image: PODCAST_SHOW_CHARCHA
  }
];

const NlPodcastRowBase = ({ collection, config }) => {
  const [shows, setShows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const collections = collection.items.filter(
    collection => collection.type === "collection"
  );

  // const defaultImgS3Key = get(
  //   config,
  //   ["publisher", "publisher", "defaulImgS3key"],
  //   ""
  // );

  if (collections.length < 1) {
    return null;
  }

  const getCollectionItems =
    collections[0].items.filter(item => {
      return item;
    }) || [];

  const handlePodcastClick = () => {
    const playerRef = getGlobalPlayerRef();
    playerRef.setCurrentScreen("library");
  };

  const fetchShow = useCallback(async () => {
    setIsLoading(true);
    try {
      const showsData = await getAcastShows();
      // console.log({ showsData }, "hiiiiiiiiiiiiii");
      const filteredShowData = showsData.shows.filter(show =>
        showIds.includes(show._id)
      );

      setShows(filteredShowData);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchShow();
  }, []);

  return (
    <div className="full-width-with-padding" styleName="wrapper">
      <div className="full-width-with-padding" styleName="inner-wrapper">
        <div className="container">
          <div styleName="main-grid">
            <div>
              <CollectionName collection={collection} />
              {collection.summary && (
                <p styleName="summary">{collection.summary}</p>
              )}
            </div>
            {isLoading ? (
              <div styleName="story-wrapper">
                <Loader />
              </div>
            ) : (
              <div styleName="story-wrapper">
                {shows.map(show => {
                  return (
                    <button
                      className="pressable"
                      styleName="podcast-btn"
                      onClick={handlePodcastClick}
                      key={show._id}
                      aria-label={`Click to view episodes of podcast: ${show.title}`}
                    >
                      <div styleName="image-wrapper">
                        <img
                          src={`${
                            showCoversMappedToCMSImages.find(
                              item => item.showId === show._id
                            ).image
                          }${COMPRESS_PARAMS_SMALL}`}
                          // src={show.cover}
                          alt={show.title}
                          styleName="image"
                        />
                      </div>
                      <h3 styleName="headline">{show.title}</h3>
                    </button>
                  );
                })}
              </div>
            )}
            {/* <div styleName="story-wrapper">
              {getCollectionItems.slice(0, 6).map((collection, index) => {
                const coverImgUrl = get(
                  collection,
                  ["metadata", "cover-image", "cover-image-s3-key"],
                  defaultImgS3Key
                );
                const coverImgMetadata = get(
                  collection,
                  ["metadata", "cover-image", "cover-image-metadata"],
                  {}
                );
                return (
                  <button
                    className="pressable"
                    onClick={handlePodcastClick}
                    key={index}
                  >
                    <figure styleName="image-wrapper">
                      <ResponsiveImage
                        slug={coverImgUrl}
                        metadata={coverImgMetadata}
                        aspectRatio={[179, 239]}
                        styleName="image"
                        defaultWidth={259}
                        widths={[250, 375]}
                        alt={collection.name}
                      />
                    </figure>
                    <h3 styleName="headline">{collection.name}</h3>
                  </button>
                );
              })}
            </div> */}
            {getCollectionItems.length > 3 && (
              <div styleName="default-img">
                <img
                  src={`${BIRUBAL_READING_NEWS_PODCAST}${COMPRESS_PARAMS_MEDIUM}`}
                  alt="Birubal reading you news"
                ></img>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

NlPodcastRowBase.propTypes = {
  collection: object,
  config: object
};

NlPodcastRowBase.storyLimit = 18;

function mapStateToProps(state) {
  return {
    config: get(state, ["qt", "config"], {})
  };
}

export const NlPodcastRow = connect(mapStateToProps)(NlPodcastRowBase);
