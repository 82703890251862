import PT from "prop-types";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { Link } from "@quintype/components";
import get from "lodash/get";
import getStoriesByCollection from "../../../utils";
import { CardImage } from "../../atoms/card-image";
import { CollectionName } from "../../atoms/collection-name";
import { Headline } from "../../atoms/headline";
import AuthorWithTime from "../../molecules/author-with-time";
import "./four-stories-with-banner.m.css";
import { COMPRESS_PARAMS_MEDIUM } from "../../../image-constants";
// import { Button } from "../../../ui/components/button";

export const FourStoriesWithBanner = ({ collection }) => {
  var stories = getStoriesByCollection(collection);
  const [location, setLocation] = useState("");
  // const collections = collection.items.filter(
  //   collection => collection.type === "collection"
  // );
  if (stories.length < 1) {
    return null;
  }

  // const hasSubscription = useSelector(state => {
  //   return get(state, ["isSubscribedUser", "userHasSubscription"], null);
  // });

  useEffect(() => {
    setLocation(global.location.host);
  }, []);

  const getStoryCard = slice => {
    return (
      stories &&
      stories.slice(0, slice).map((story, index) => {
        const section = get(story, ["sections", "0"], {});
        const getLabel = get(
          story,
          ["metadata", "story-attributes", "storyattribute", 0],
          ""
        );
        return (
          <article>
            <div key={index} styleName="story-wrapper">
              <CardImage story={story} />
              {getLabel && <div styleName="ribbon">{getLabel}</div>}
              <div styleName="text-wrapper">
                <Link href={section["section-url"]}>
                  <p
                    styleName="section-badge"
                    aria-label={`Section:${
                      section["display-name"] || section.name
                    } `}
                  >
                    {section["display-name"] || section.name}
                  </p>
                </Link>
                {/* <Link href={section["section-url"]}>
                <div styleName="section">
                  {section["display-name"] || section.name}
                </div>
              </Link> */}
                <Headline story={story} externalLink={true} />
                <div styleName="author-details">
                  <AuthorWithTime story={story} />
                </div>
                {/* <Button
                variant="neutral"
                style={{ margin: "8px 0" }}
                styleName="text-red"
              >
                Button
              </Button> */}
              </div>
            </div>
          </article>
        );
      })
    );
  };

  const hindiPage =
    location === "hindi.newslaundry.com" ||
    location === "newslaundry-hindi-web.qtstage.io" ||
    location === "hindi.lvh.me:3000";

  if (!hindiPage) {
    stories = stories.filter(item => item.url.slice(8, 13) !== "hindi");
  }
  return (
    <div className="full-width-with-padding" styleName="wrapper">
      <div styleName="container" className="container">
        {<CollectionName collection={collection} />}
        <div styleName="layout-grid">
          <>{getStoryCard(4)}</>

          <Link
            styleName="sena-wrapper"
            href={`/download-app?ref=vertical-banner-homepage`}
            // href={`https://youtube.com/live/bdsypz3qi7A`}
          >
            <img
              styleName="banner"
              src={`https://images.assettype.com/newslaundry/2024-06/4b8acaf8-6862-4e91-bf7a-8974d2067264/homepage${COMPRESS_PARAMS_MEDIUM}`}
              // src="https://images.assettype.com/newslaundry/2024-06/3455d9c3-1a77-4047-bcdf-e067cf0781a8/websig"
              alt="Click to download newslaundry app"
              // alt="Click to watch newslaundry live on youtube"
            />
            {/* <div styleName="lower-wrapper" >
              <h2>Pay to keep news free</h2>
              <p>
                Independent journalism is not possible until you pitch in.
                We have seen what happens in ad-funded models: Journalism takes
                backseat and gets sacrificed at the altar of clicks and TRPs.
                <br /> <br />
                Subscribe now to power our journalism.
              </p>
            </div> */}
          </Link>
        </div>
      </div>
    </div>
  );
};

FourStoriesWithBanner.propTypes = {
  collection: PT.object
};
