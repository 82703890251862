/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { Link } from "@quintype/components";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BLOCKS, GIFTBLOCKS, TEXT } from "../constants";
import { SubscriptionHelper } from "../subscription-helpers";
import IntegrationData from "../../../../../../config/integrations";
// import { VisuallyHidden } from "../../../../ui/components";
// import assetify from "@quintype/framework/assetify";
// import merch from "../../../../../assets/images/nl-images/merch.png";
// import gift from "../../../../../assets/images/nl-images/nl-sena-page.png";
// import logo from "../../../../../assets/images/logo-nl.png";
import "./subscription-blocks.m.css";
import {
  COMPRESS_PARAMS_MEDIUM,
  COMPRESS_PARAMS_SMALL
} from "../../../../image-constants";
// import { da } from "date-fns/locale";

// const hindiPlanIds = [1367, 338, 1369, 340, 4060, 4061];
// const englishIndianPlanIds = [338, 1369, 340, 1378, 343];
// enter plan ids like: [staging Disruptor planID, staging Game changer planID, prod Disruptor planID, prod Game changer planID ] in tha array below
const englishIndianYearlyPlanIds = [1369, 1378, 340, 343];
const englishIndianMonthlyPlanIds = [1367, 1376, 338, 341];
const englishOverseasMonthlyPlanIds = [1376, 341];
const englishOverseasYearlyPlanIds = [1378, 343];
// const hindiYearlyPlanIds = [1369, 1378, 340, 343];
// const hindiMonthlyPlanIds = [1367, 1376, 338, 341];
const hindiPlanIds = [4671, 4672]; // monthly and 6 month
const jointYearlyPlanIds = [4455, 4460, 4471, 4476];
const jointMonthlyPlanIds = [4453, 4457, 4474, 4478];
const jointOverseasYearlyPlanIds = [4456, 4460, 4471, 4476];
const jointOverseasMonthlyPlanIds = [4454, 4458, 4474, 4478];
// const jointOverseasYearlyPlanIds = [4456, 4460, 4472, 4475];
// const jointOverseasMonthlyPlanIds = [4454, 4458, 4473, 4477];

export const SubscriptionBlocks = props => {
  const IS_STAGING = IntegrationData().isAccesstypeStaging;
  const [activeBtn, setActivebtn] = useState("yearly");
  const userCountryInfo = useSelector(state =>
    get(state, ["fetchUserCountry"], {
      country_code: "",
      country_code_alias: "",
      country_name: "",
      user_timezone: ""
    })
  );
  // const { qt } = useSelector(state => state);
  // const authHost = qt && qt.config.publisher.publisher.auth_host;
  const userCountry = {
    country_code: userCountryInfo.country_code,
    country_name: userCountryInfo.country_name,
    country_code_alias: userCountryInfo.country_code_alias,
    user_timezone: userCountryInfo.user_timezone
  };

  const member = useSelector(state => get(state, ["member"], null));
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [jointSelectedBlocks, setJointSelectedBlocks] = useState([]);
  // const [plans, setPlans] = useState("yearly");
  const [isHindi, setIsHindi] = useState(false);
  // const [customHelperGCText, setCustomHelperGCText] = useState(null);
  const [isDisruptor, setIsDisruptor] = useState(false);
  const [isIndian, setIsIndian] = useState(false);

  useEffect(() => {
    setIsHindi(!!(global.location && global.location.host.includes("hindi")));
    console.log({ userCountry }, "user country");
    if (
      userCountry.country_code === "IN" ||
      userCountry.country_code_alias === "IN"
      // false
    ) {
      setIsIndian(true);
      const isHindiWeb =
        !!global.location && global.location.host.includes("hindi");
      // const isHindiWeb = true;

      if (isHindiWeb) {
        // console.log(BLOCKS);
        setSelectedBlocks(
          BLOCKS.filter(
            block =>
              block.planId !== 2033 && hindiPlanIds.includes(block.planId)
          )
        );
        // if (activeBtn == "monthly") {
        //   setSelectedBlocks(
        //     BLOCKS.filter(
        //       (block) =>
        //         block.planId !== 2033 &&
        //         hindiMonthlyPlanIds.includes(block.planId)
        //     )
        //   );
        //   setJointSelectedBlocks(
        //     BLOCKS.filter(
        //       (block) =>
        //         block.planId !== 2033 &&
        //         jointMonthlyPlanIds.includes(block.planId)
        //     )
        //   );
        // } else {
        //   setSelectedBlocks(
        //     BLOCKS.filter(
        //       (block) =>
        //         block.planId !== 2033 &&
        //         hindiYearlyPlanIds.includes(block.planId)
        //     )
        //   );
        //   setJointSelectedBlocks(
        //     BLOCKS.filter(
        //       (block) =>
        //         block.planId !== 2033 &&
        //         jointYearlyPlanIds.includes(block.planId)
        //     )
        //   );
        // }
      } else {
        if (activeBtn === "monthly") {
          setSelectedBlocks(
            BLOCKS.filter(
              block =>
                block.planId !== 2033 &&
                englishIndianMonthlyPlanIds.includes(block.planId)
            )
          );
          setJointSelectedBlocks(
            BLOCKS.filter(
              block =>
                block.planId !== 2033 &&
                jointMonthlyPlanIds.includes(block.planId)
            )
          );
        } else {
          setSelectedBlocks(
            BLOCKS.filter(
              block =>
                block.planId !== 2033 &&
                englishIndianYearlyPlanIds.includes(block.planId)
            )
          );
          setJointSelectedBlocks(
            BLOCKS.filter(
              block =>
                block.planId !== 2033 &&
                jointYearlyPlanIds.includes(block.planId)
            )
          );
        }
      }
    } else {
      if (activeBtn === "monthly") {
        // console.log(
        //   BLOCKS.filter(
        //     (block) =>
        //       block.planId !== 2033 &&
        //       englishOverseasMonthlyPlanIds.includes(block.planId)
        //   )
        // );
        // console.log(
        //   BLOCKS.filter(
        //     (block) =>
        //       block.planId !== 2033 &&
        //       jointOverseasMonthlyPlanIds.includes(block.planId)
        //   )
        // );

        setSelectedBlocks(
          BLOCKS.filter(
            block =>
              block.planId !== 2033 &&
              englishOverseasMonthlyPlanIds.includes(block.planId)
          )
        );
        setJointSelectedBlocks(
          BLOCKS.filter(
            block =>
              block.planId !== 2033 &&
              jointOverseasMonthlyPlanIds.includes(block.planId)
          )
        );
      } else {
        // console.log(
        //   BLOCKS.filter(
        //     (block) =>
        //       block.planId !== 2033 &&
        //       englishOverseasYearlyPlanIds.includes(block.planId)
        //   )
        // );
        // console.log(
        //   BLOCKS.filter(
        //     (block) =>
        //       block.planId !== 2033 &&
        //       jointOverseasYearlyPlanIds.includes(block.planId)
        //   )
        // );
        setSelectedBlocks(
          BLOCKS.filter(
            block =>
              block.planId !== 2033 &&
              englishOverseasYearlyPlanIds.includes(block.planId)
          )
        );
        setJointSelectedBlocks(
          BLOCKS.filter(
            block =>
              block.planId !== 2033 &&
              jointOverseasYearlyPlanIds.includes(block.planId)
          )
        );
      }
    }
    // console.log({ selectedBlocks }, { jointMonthlyPlanIds });
  }, [activeBtn, isDisruptor]);

  useEffect(() => {
    if (
      !userCountry.country_code === "IN" ||
      !userCountry.country_code_alias === "IN"
    ) {
      // setCustomHelperGCText("You save ₹300/month");
    }
    // console.log(selectedBlocks);
  }, [isHindi, selectedBlocks, jointSelectedBlocks]);

  return (
    <div styleName="wrapper" className="full-width">
      <div styleName={props.gift ? "top-gift-wrapper" : "top-wrapper"}>
        <h2 styleName="title">
          {!props.gift ? TEXT.subscriptionTitle : TEXT.giftTitle}
        </h2>
        {/* <div styleName="red-line"></div> */}
        <p styleName="helper-text">
          {!props.gift ? TEXT.subscriptionDescription : TEXT.giftDescription}
        </p>
        {isHindi ? (
          <>
            <br />
            <br />
          </>
        ) : null}
      </div>

      {!props.gift ? (
        <>
          {/* {" "}
          {!isHindi && (
            <div styleName="tabberWrapper">
              <div styleName="chartTabber">
                <button
                  aria-pressed={activeBtn === "monthly"}
                  aria-label="show monthly plans"
                  styleName={activeBtn === "monthly" ? "active" : ""}
                  onClick={() => {
                    setActivebtn("monthly");
                    // setPlans("monthly");
                  }}
                  id="monthly"
                >
                  Monthly
                </button>

                <button
                  aria-pressed={activeBtn === "yearly"}
                  aria-label=" Show annual plans with 15% savings"
                  styleName={activeBtn === "yearly" ? "active" : ""}
                  onClick={() => {
                    setActivebtn("yearly");
                    // setPlans("yearly");
                    // console.log({ selectedBlocks }, { jointSelectedBlocks });
                  }}
                  id="yearly"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    Annual
                    <div styleName="discount-wrapper-2">
                      <p>15% off</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          )} */}
          <div
            styleName={`block-wrapper ${
              !userCountry.country_code === "IN" ||
              !userCountry.country_code_alias === "IN" ||
              isHindi
                ? // true
                  "two-col-block-wrapper two-col-grid"
                : "block-wrapper three-col-grid"
            }`}
          >
            {/* {selectedBlocks.length && (
              <>
                {" "}
                <Block
                  data={selectedBlocks[0]}
                  key={123}
                  value={123}
                  member={member}
                  URL={props.URL}
                  isHindi={isHindi}
                  intent="self_subscription"
                  customHelperDisruptorText={null}
                  customHelperGCText={null}
                  activeBtn={activeBtn}
                />
                {!isHindi ? (
                  jointSelectedBlocks.length && (
                    <JointBlock
                      data={jointSelectedBlocks[!isDisruptor ? 0 : 1]}
                      setIsDisruptor={setIsDisruptor}
                      isDisruptor={isDisruptor}
                      key={125}
                      value={125}
                      member={member}
                      URL={props.URL}
                      isHindi={isHindi}
                      intent="self_subscription"
                      customHelperDisruptorText={null}
                      customHelperGCText={null}
                    />
                  )
                ) : (
                  <></>
                )}
                {selectedBlocks[1] && (
                  <Block
                    data={selectedBlocks[1]}
                    key={124}
                    value={124}
                    member={member}
                    URL={props.URL}
                    isHindi={isHindi}
                    intent="self_subscription"
                    customHelperDisruptorText={null}
                    customHelperGCText={null}
                    activeBtn={activeBtn}
                  />
                )}
              </>
            )} */}

            {global.location && global.location.host.includes("hindi") ? (
              <>
                {selectedBlocks[0] ? (
                  <Block
                    data={selectedBlocks[0]}
                    key={123}
                    value={123}
                    member={member}
                    URL={props.URL}
                    isHindi={isHindi}
                    intent="self_subscription"
                    customHelperDisruptorText={null}
                    customHelperGCText={null}
                    activeBtn={activeBtn}
                  />
                ) : (
                  <></>
                )}
                {selectedBlocks[1] ? (
                  <Block
                    data={selectedBlocks[1]}
                    key={124}
                    value={124}
                    member={member}
                    URL={props.URL}
                    isHindi={isHindi}
                    intent="self_subscription"
                    customHelperDisruptorText={null}
                    customHelperGCText={null}
                    activeBtn={activeBtn}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {userCountry.country_code === "IN" ||
                userCountry.country_code_alias === "IN" ? (
                  <>
                    <JointBlock
                      data={{
                        groupId: IS_STAGING ? 759 : 114,
                        planId: IS_STAGING ? 4453 : 4474,
                        displayName: "Disruptor Monthly",
                        groupName: "Disruptor Monthly Joint Subscription",
                        planName: "Joint Subscription Monthly",
                        price: "500",
                        benefits: [
                          "Paywall stories on both Newslaundry and The News Minute",
                          "Priority access to all meet ups and events, including The Media Rumble ",
                          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
                        ],
                        strikedBenefits: [
                          "Stronger together merch – Fridge magnets and laptop stickers",
                          "RSS feed of all paywall podcasts"
                        ],
                        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
                      }}
                      setIsDisruptor={setIsDisruptor}
                      isDisruptor={isDisruptor}
                      key={125}
                      value={125}
                      member={member}
                      URL={props.URL}
                      isHindi={isHindi}
                      isHighlighted={false}
                      intent="self_subscription"
                      customHelperDisruptorText={null}
                      customHelperGCText={null}
                    />
                    <JointBlock
                      data={{
                        groupId: IS_STAGING ? 759 : 114,
                        planId: IS_STAGING ? 4455 : 4471,
                        displayName: "Disruptor Annual",
                        groupName: "Disruptor Annual Joint Subscription",
                        planName: "Joint Subscription Annual",
                        price: "4999",
                        originalPrice: "6000",
                        benefits: [
                          " Paywall stories on both Newslaundry and The News Minute",
                          "Stronger together merch – Fridge magnets and laptop stickers",
                          "Priority access to all meet ups and events, including The Media Rumble",
                          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
                        ],
                        strikedBenefits: ["RSS feed of all paywall podcasts"],
                        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
                      }}
                      setIsDisruptor={setIsDisruptor}
                      isDisruptor={isDisruptor}
                      key={125}
                      value={125}
                      member={member}
                      URL={props.URL}
                      isHindi={isHindi}
                      isHighlighted={true}
                      intent="self_subscription"
                      customHelperDisruptorText={null}
                      customHelperGCText={null}
                    />
                    <JointBlock
                      data={{
                        groupId: IS_STAGING ? 760 : 113,
                        planId: IS_STAGING ? 4460 : 4476,
                        displayName: "Game Changer Annual",
                        groupName: "Game Changer Annual Joint Subscription",
                        planName: "Joint Subscription Annual",
                        price: "15999",
                        originalPrice: "19000",
                        benefits: [
                          "Paywall stories on both Newslaundry and The News Minute ",
                          "RSS feed of all paywall podcasts",
                          "Stronger together merch – Fridge magnets, laptop stickers, mug",
                          "Priority access to all meet ups and events, including The Media Rumble",
                          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
                        ],
                        strikedBenefits: [],
                        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
                      }}
                      setIsDisruptor={setIsDisruptor}
                      isDisruptor={isDisruptor}
                      key={125}
                      value={125}
                      member={member}
                      URL={props.URL}
                      isHindi={isHindi}
                      isHighlighted={false}
                      intent="self_subscription"
                      customHelperDisruptorText={null}
                      customHelperGCText={null}
                    />
                  </>
                ) : (
                  <>
                    <JointBlock
                      data={{
                        groupId: IS_STAGING ? 760 : 113,
                        planId: IS_STAGING ? 4460 : 4476,
                        displayName: "Game Changer Annual",
                        groupName: "Game Changer Annual Joint Subscription",
                        planName: "Joint Subscription Annual",
                        price: "15999",
                        originalPrice: "19000",
                        benefits: [
                          "Paywall stories on both Newslaundry and The News Minute ",
                          "RSS feed of all paywall podcasts",
                          "Stronger together merch – Fridge magnets, laptop stickers, mug",
                          "Priority access to all meet ups and events, including The Media Rumble",
                          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
                        ],
                        strikedBenefits: [],
                        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
                      }}
                      setIsDisruptor={setIsDisruptor}
                      isDisruptor={isDisruptor}
                      key={125}
                      value={125}
                      member={member}
                      URL={props.URL}
                      isHindi={isHindi}
                      isHighlighted={false}
                      intent="self_subscription"
                      customHelperDisruptorText={null}
                      customHelperGCText={null}
                    />
                    <JointBlock
                      data={{
                        groupId: IS_STAGING ? 759 : 114,
                        planId: IS_STAGING ? 4455 : 4471,
                        displayName: "Disruptor Annual",
                        groupName: "Disruptor Annual Joint Subscription",
                        planName: "Joint Subscription Annual",
                        price: "4999",
                        originalPrice: "6000",
                        benefits: [
                          " Paywall stories on both Newslaundry and The News Minute",
                          "Stronger together merch – Fridge magnets and laptop stickers",
                          "Priority access to all meet ups and events, including The Media Rumble",
                          "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team"
                        ],
                        strikedBenefits: ["RSS feed of all paywall podcasts"],
                        img: "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
                      }}
                      setIsDisruptor={setIsDisruptor}
                      isDisruptor={isDisruptor}
                      key={125}
                      value={125}
                      member={member}
                      URL={props.URL}
                      isHindi={isHindi}
                      isHighlighted={true}
                      intent="self_subscription"
                      customHelperDisruptorText={null}
                      customHelperGCText={null}
                    />
                  </>
                )}
              </>
            )}
          </div>
          {/* <p styleName="lower-text">
            <b>
              {" "}
              Reach out to us at{" "}
              <a
                href="mailto:subscription@newslaundry.com"
                style={{ color: "#ec2227" }}
              >
                subscription@newslaundry.com
              </a>{" "}
              for any issues related to your subscription.{" "}
            </b>
          </p> */}
          <div styleName={`feature odd`}>
            <img
              src={
                isHindi
                  ? `https://images.assettype.com/newslaundry/2024-07/a5b83d39-b54c-4ba1-9c44-aa80e0c28dc2/Hindi Sub page QR.png${COMPRESS_PARAMS_MEDIUM}`
                  : `https://images.assettype.com/newslaundry/2024-04/83923e71-a99b-4bdd-85c1-213154a9b18f/Subscription_page_QR.png${COMPRESS_PARAMS_MEDIUM}`
              }
              alt={"Scan this to pay"}
            />
            <div styleName="text-wrapper">
              <h3>
                {isHindi
                  ? "अब आप क्यूआर कोड स्कैन करके सीधे भुगतान कर सकते हैं"
                  : "Scan the QR code to pay directly"}
              </h3>
              {isHindi ? (
                <p>
                  खबरों के लिए भुगतान करना आसान और तेज़ होना चाहिए. यदि आप लॉगिन
                  और अन्य झंझटों से बचना चाहते हैं तो आप सीधे क्यूआर कोड स्कैन
                  कर सकते हैं या फिर यहां{" "}
                  <a href="https://razorpay.com/payment-button/pl_OZc02G2Pd7ErAt/view">
                    <u>क्लिक</u>
                  </a>{" "}
                  करके सीधे सदस्यता ले सकते हैं. बाकी का काम हम संभाल लेंगे.
                  आपके ईमेल पर सदस्यता संबंधी सारी जानकारी भेज दी जाएगी. क्यूआर
                  कोड के माध्यम से भुगतान करने के बाद, कृपया हमारी सदस्यता को
                  सक्रिय करने के लिए हमें कुछ घंटे का समय दें. धन्यवाद
                </p>
              ) : (
                <p>
                  Paying for news should be quick and easy. If you want to skip
                  login and other steps, you can scan the QR code or click{" "}
                  <a href="https://razorpay.com/payment-button/pl_O4RzISuNw9qaTC/view">
                    <u>here</u>
                  </a>{" "}
                  to pay for subscription directly. We will take care of the
                  rest and email you the subscription details. Once you have
                  paid via the QR code, please give us a few hours to activate
                  your subscription.
                </p>
              )}
            </div>
          </div>
        </>
      ) : (
        <GiftBlocks member={member} />
      )}
      <SubscriptionHelper {...props} gift={props.gift} />
    </div>
  );
};

const Block = ({
  data,
  value,
  gift,
  member,
  intent = "",
  isHindi = false,
  customHelperDisruptorText,
  customHelperGCText,
  activeBtn = "yearly"
}) => {
  const isRecurring = useSelector(state =>
    get(state, ["fetchSubscription", "isRecurring"], null)
  );
  const { fetchSubscription } = useSelector(state => state);
  const { qt } = useSelector(state => state);
  const authHost = qt && qt.config.publisher.publisher.auth_host;
  // console.log({ authHost });
  const handleBtnClick = () => {
    const obj = {
      event: "subscription_addToCart",
      subscription_category: "self_subscription",
      subscription_plan: `${data.groupName} - ${data.planName}`,
      price: data.price,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);

    isRecurring &&
      window.alert(
        "Oops!You already have a recurring subscription. Try sena, student or gift subscription."
      );
  };

  const oldRedirectURL = new URL(
    `https://${isHindi ? "hindi" : "www"}.newslaundry.com/subscription/checkout`
  );
  const searchParams = new URLSearchParams(oldRedirectURL.search);
  searchParams.append("intent", intent);
  searchParams.append("plan", data.planId);
  // console.log({ searchParams });

  const redirectURL = new URL(
    `${oldRedirectURL.origin}${
      oldRedirectURL.pathname
    }?${searchParams.toString()}`
  );

  return (
    <div
      styleName={activeBtn === "monthly" || isHindi ? "block-monthly" : "block"}
    >
      {/* {customHelperGCText && <small>{customHelperGCText}</small>} */}
      {/* {customHelperDisruptorText &&
        (data.planId === 1367 || data.planId === 338) && (
          <small>{customHelperDisruptorText}</small>
        )} */}
      <h3 styleName="block-title">{data.groupName}</h3>
      <div styleName="price-duration-wrapper">
        <div styleName="price-wrapper">
          {data.originalPrice && (
            <span aria-label="actual price">
              <strike>{data.originalPrice}</strike>
            </span>
          )}
          <h2 styleName="price" aria-label="discounted price">
            &#8377;{data.price}
          </h2>
        </div>
        <div styleName="duration">
          <p>{data.planName}</p>
        </div>
      </div>
      {data.originalPrice ? (
        <div styleName="discount-wrapper">
          <p>&#8377; {+data.originalPrice - +data.price} off</p>
        </div>
      ) : (
        <div></div>
      )}
      {!gift && (
        <Link
          href={
            !member
              ? `${authHost}/user-login?callback-url=https://${
                  isHindi ? "hindi" : "www"
                }.newslaundry.com&redirect-url=${redirectURL.href}`
              : !isRecurring
              ? `/subscription/checkout?intent=${intent}&plan=${data.planId}`
              : ""
          }
        >
          <button onClick={handleBtnClick}>SUBSCRIBE NOW</button>
        </Link>
      )}
      <ul aria-label="plan benefits" styleName="benefits">
        {data.benefits.map((benefit, index) => {
          return (
            <li key={index}>
              <i
                className="material-icons"
                style={{
                  color: "var(--component-background-success-solid-default)"
                }}
              >
                check
              </i>
              <span dangerouslySetInnerHTML={{ __html: benefit }} />
            </li>
          );
        })}
        {/* {data.plus && (
          <p styleName="plus-wrapper">
            <span styleName="plus-left-border"></span>
            <span>PLUS</span>
            <span styleName="plus-right-border"></span>
          </p>
        )} */}
        {data.plus &&
          data.plus.map((benefit, index) => {
            return (
              <li key={index}>
                <i
                  className="material-icons"
                  style={{
                    color: "var(--component-background-success-solid-default)"
                  }}
                >
                  check
                </i>
                <span dangerouslySetInnerHTML={{ __html: benefit }} />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const JointBlock = ({
  data,
  setIsDisruptor,
  isDisruptor,
  value,
  gift,
  member,
  intent = "",
  isHindi = false,
  isHighlighted = false,
  customHelperDisruptorText,
  customHelperGCText
}) => {
  // console.log({ data }, "data");
  const isRecurring = useSelector(state =>
    get(state, ["fetchSubscription", "isRecurring"], null)
  );
  const { qt } = useSelector(state => state);
  const authHost = qt && qt.config.publisher.publisher.auth_host;
  const { fetchSubscription } = useSelector(state => state);
  // useEffect(() => {
  //   console.log({ data });
  // }, []);

  const handleBtnClick = () => {
    const obj = {
      event: "subscription_addToCart",
      subscription_category: "self_subscription",
      subscription_plan: `${data.groupName} - ${data.planName}`,
      price: data.price,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);

    isRecurring &&
      window.alert(
        "Oops!You already have a recurring subscription. Try sena, student or gift subscription."
      );
  };

  const oldRedirectURL = new URL(
    `https://${isHindi ? "hindi" : "www"}.newslaundry.com/subscription/checkout`
  );
  const searchParams = new URLSearchParams(oldRedirectURL.search);
  searchParams.append("intent", intent);
  searchParams.append("plan", data.planId);

  const redirectURL = new URL(
    `${oldRedirectURL.origin}${
      oldRedirectURL.pathname
    }?${searchParams.toString()}`
  );
  // console.log({ isHindi }, "hindi");
  return (
    <div styleName="joint-block-wrapper">
      {/* {data.displayName.toLowerCase().includes("disruptor annual") ? ( */}
      {isHighlighted ? (
        <div styleName="pricing-plan-tag">Most popular</div>
      ) : (
        <></>
      )}
      <div styleName="joint-block-1">
        <h3 styleName="joint-block-title">NL-TNM Joint Subscription</h3>
        <img
          styleName="grp-img"
          src={`https://images.assettype.com/newslaundry/2023-10/3fb73eb2-de53-4ec9-bf75-1ecc648ec14f/Frame_12__1_.png${COMPRESS_PARAMS_SMALL}`}
          alt="NL-TNM logo"
        />
        <div styleName="duration">
          <p>{data.displayName}</p>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <p styleName={!isDisruptor ? "activeName" : "name"}>Disruptor</p>
          <label htmlFor="plan-toggle" styleName="toggle">
            <input
              styleName="toggle-input"
              type="checkbox"
              id="plan-toggle"
              onChange={() => {
                // console.log("hii");
                setIsDisruptor(!isDisruptor);
                // setPlans("monthly");
              }}
            />
            <VisuallyHidden>
              {`Currently selected ${
                isDisruptor ? "Disruptor" : "Game changer"
              }. Toggle to select ${
                isDisruptor ? "Game changer" : "Disruptor"
              }`}
            </VisuallyHidden>
            <span styleName="toggle-label" data-off="" data-on=""></span>
            <span styleName="toggle-handle"></span>
          </label>
          <p styleName={isDisruptor ? "activeName" : "name"}>Game Changer</p>
        </div> */}
        {/* {data.originalPrice ? ( */}
        {/* <div styleName="discount-wrapper">
          <p>Most Valuable</p>
        </div> */}
        {/* ) : (
        <div></div>
      )} */}
        {/* <hr style={{backgroundColor:"gray", height:"1px", border:"none", width:"110%", marginLeft:"-5%", marginTop:"-0.5rem"}}/> */}
      </div>

      <div styleName="joint-block-2">
        <div styleName="joint-price-duration-wrapper">
          <div styleName="price-wrapper">
            {data.originalPrice && (
              <span aria-label="Actual price">
                <strike>{data.originalPrice}</strike>
              </span>
            )}
            <h2 styleName="price" aria-label="discounted price">
              &#8377;{data.price}
            </h2>
          </div>

          {data.originalPrice ? (
            <div styleName="discount-wrapper">
              <p>&#8377; {+data.originalPrice - +data.price} off</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {!gift && (
          <Link
            href={
              !member
                ? `${authHost}/user-login?callback-url=https://${
                    isHindi ? "hindi" : "www"
                  }.newslaundry.com&redirect-url=${redirectURL.href}`
                : !isRecurring
                ? `/subscription/checkout?intent=${intent}&plan=${data.planId}`
                : ""
            }
          >
            <button onClick={handleBtnClick}>SUBSCRIBE NOW</button>
          </Link>
        )}
        <ul styleName="joint-benefits" aria-label="plan benefits">
          {data.benefits.map((benefit, index) => {
            return (
              <li key={index}>
                <i
                  className="material-icons"
                  style={{
                    color: "var(--component-background-success-solid-default)"
                  }}
                >
                  check
                </i>
                <span dangerouslySetInnerHTML={{ __html: benefit }} />
                {/* {benefit} */}
              </li>
            );
          })}
          {data.plus && (
            <p>
              <span style={{ color: "black" }}>PLUS</span>
            </p>
          )}
          {data.plus &&
            data.plus.map((benefit, index) => {
              return (
                <li key={index}>
                  <i
                    className="material-icons"
                    style={{
                      color: "var(--component-background-success-solid-default)"
                    }}
                  >
                    check
                  </i>
                  <span dangerouslySetInnerHTML={{ __html: benefit }} />
                  {/* {benefit} */}
                </li>
              );
            })}
          {data.strikedBenefits.map((benefit, index) => {
            return (
              <li key={index}>
                <i
                  className="material-icons"
                  style={{
                    color: "red"
                  }}
                >
                  close
                </i>
                <strike>
                  <span dangerouslySetInnerHTML={{ __html: benefit }} />
                </strike>
                {/* {benefit} */}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const GiftBlocks = ({ member }) => {
  const [data, setData] = React.useState({
    sender_name: member && (member.name || member.userName || ""),
    recipient_name: "",
    recipient_email: "",
    message: "",
    subscription_details: {
      sub_details: "Disruptor Annual",
      amount: 3000
    }
  });
  const [error, setError] = React.useState(null);
  const { recipient_name, recipient_email, message } = data;
  const planId = GIFTBLOCKS[0].planId;
  const dispatch = useDispatch();

  const { fetchSubscription } = useSelector(state => state);

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const obj = {
      event: "subscription_addToCart",
      subscription_category: "gift_subscription",
      subscription_plan: `Disruptor - Yearly`,
      price: "3,000",
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);

    try {
      if (!member) {
        window.location = `https://auth.newslaundry.com/user-login?callback-url=https://www.newslaundry.com&redirect-url=https://www.newslaundry.com/gift&intent=gift_subscription`;
        return false;
      }

      setData({ loading: true });
      window.localStorage.setItem("giftData", JSON.stringify(data));
      global.app.navigateToPage(
        dispatch,
        `/subscription/checkout?plan=${planId}&gift=true`
      );
    } catch (e) {
      console.log(e);
      setError(e);
    } finally {
      setData({ loading: false });
    }
  };

  return (
    <div styleName="gift-block-wrapper">
      <form styleName="gift-form" onSubmit={handleSubmit}>
        <h2>Enter Recipient's Information</h2>
        <div styleName="form-group">
          <label styleName="text">Recipient's Name*</label>
          <input
            styleName="input-text"
            required
            value={recipient_name}
            id="recipient_name"
            type="text"
            placeholder="Name"
            name="recipient_name"
            onChange={handleChange}
          ></input>
        </div>
        <div styleName="form-group">
          <label styleName="text">Recipient's Email*</label>
          <input
            styleName="input-text"
            required
            value={recipient_email}
            id="recipient's email*"
            type="email"
            placeholder="Email"
            name="recipient_email"
            onChange={handleChange}
          ></input>
        </div>
        <div styleName="form-group">
          <label styleName="text">Send a message</label>
          <textarea
            styleName="input-text"
            value={message}
            id="message"
            type="textarea"
            placeholder="Message"
            name="message"
            onChange={handleChange}
          ></textarea>
        </div>
        <button type="submit">Gift Subscription</button>
        <small>{error}</small>
      </form>
      {GIFTBLOCKS.map((block, index) => {
        return (
          <Block
            data={block}
            key={index}
            value={index}
            gift={true}
            member={member}
            intent="gift_subscription"
          />
        );
      })}
    </div>
  );
};
