/* eslint-disable react/prop-types */
import React from "react";
import { CollectionName } from "../../atoms/collection-name";
import {
  BIRUBAL_READING_NEWS_PODCAST,
  COMPRESS_PARAMS_MEDIUM,
  PODCAST_SHOW_CHARCHA
} from "../../../image-constants";
// import NlPodcast from "../../../../assets/images/nlpodcast.png";
// import NlPotli from "../../../../assets/images/potli.jpeg";
// import NlCharcha from "../../../../assets/images/charcha.jpeg";
// import assetify from "@quintype/framework/assetify";
import { getGlobalPlayerRef } from "../../podcast-player/utils";
// import { PlayerContextProvider } from "../../podcast-player/player-context";

import "./nl-hindi-podcast.m.css";

export const NlHindiPodcastRow = ({ collection }, props) => {
  const handlePodcastClick = show => {
    const playerRef = getGlobalPlayerRef();
    playerRef.setCurrentScreen("library");
    // playerRef.setCurrentShow(show)
  };

  return (
    <div className="full-width-with-padding" styleName="wrapper">
      <div className="full-width-with-padding" styleName="inner-wrapper">
        <div className="container">
          <div styleName="main-grid">
            <div styleName="intro-wrapper">
              <CollectionName collection={collection} color="#fff" />
              {collection.summary && (
                <h2 styleName="summary">{collection.summary}</h2>
              )}
            </div>
            <div styleName="story-wrapper">
              <div
                styleName="thumb"
                onClick={() => handlePodcastClick("charcha")}
              >
                <img
                  src={`${PODCAST_SHOW_CHARCHA}${COMPRESS_PARAMS_MEDIUM}`}
                  alt="nl-charcha"
                  style={{ border: "2px solid white" }}
                  styleName="cover-image"
                />
                <a />
                <h3 styleName="headline"> एनएल चर्चा </h3>
              </div>
              {/* &nbsp;&nbsp;&nbsp; */}
              {/* <div
                styleName="thumb"
                onClick={() => handlePodcastClick("potli")}
              >
                <img
                  width="100%"
                  src={assetify(NlPotli)}
                  alt="nl-potli"
                  style={{ border: "2px solid white" }}
                />
                <a />
                <h3 styleName="headline">न्यूज़ पोटली</h3>
              </div> */}
            </div>
            <div styleName="default-img">
              <img
                src={`${BIRUBAL_READING_NEWS_PODCAST}${COMPRESS_PARAMS_MEDIUM}`}
                alt="Birubal reading you news"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
