import { Link } from "@quintype/components";
import React from "react";
import PropTypes from "prop-types";
import { useScrollLock } from "../helper/hooks";
// import SubscriptionNudge from "../../../assets/images/nl-images/stopnudge1.png";
// import logo from "../../../assets/images/newslaundry_logo.png";
// import assetify from "@quintype/framework/assetify";

import { FaTimes } from "react-icons/fa";
import "./subscription-appeal-banner.m.css";
import { Button } from "../../ui/components";
import {
  COMPRESS_PARAMS_EXTRA_SMALL,
  COMPRESS_PARAMS_MEDIUM,
  NEWSLAUNDRY_SQUARE_LOGO,
  TRAFFIC_POLICE_BIRUBAL_STOP_NUDGE
} from "../../image-constants";

const SubscriptionAppealBanner = ({ setToShowPopup }) => {
  const isHindi = !!(global.location && global.location.host.includes("hindi"));

  useScrollLock();

  return (
    <div styleName="wrapper">
      <div styleName="left-container">
        <img
          src={`${TRAFFIC_POLICE_BIRUBAL_STOP_NUDGE}${COMPRESS_PARAMS_MEDIUM}`}
          alt="subscription-appeal-image"
          styleName="subscription-appeal-image"
        />
      </div>
      <div styleName="middle-container">
        <div styleName="row-wrapper">
          <div styleName="logo-wrapper">
            <img
              src={`${NEWSLAUNDRY_SQUARE_LOGO}${COMPRESS_PARAMS_EXTRA_SMALL}`}
              alt="newslaundry logo"
            />
          </div>
          <h2 styleName="text-container">
            <span>Support Independent Media</span>
          </h2>
        </div>
        <div styleName="appeal-wrapper">
          <p styleName="appeal-text">
            {isHindi
              ? "एक स्वतंत्र मीडिया जिन सवालों के जवाब मिलने चाहिए उन सवालों को पूछ कर जनतंत्र को थामे रखता है, जिन कहानियों को कहना जरूरी है उन्हें कह कर उसे बचाए रखता है. स्वतंत्र पत्रकारिता का समर्थन करें. आज ही सब्सक्राइब करें."
              : "Independent media plays a critical role in sustaining democracy, telling stories that need to be told and asking questions that demand answers. Support independent journalism."}
          </p>
          <Link
            styleName="redirect-link"
            href="/subscription?ref=homepage-subscription-banner"
          >
            <span>{isHindi ? "सब्सक्राइब करें" : "Subscribe Now"}</span>
          </Link>
        </div>
      </div>
      <div styleName="cancel-btn-div">
        <Button onClick={() => setToShowPopup("closed")}>
          <FaTimes />
        </Button>
        {/* <button styleName="cancel-btn" onClick={() => setToShowPopup("closed")}>
          <FaTimes />
        </button> */}
      </div>
    </div>
  );
};

SubscriptionAppealBanner.propTypes = {
  setToShowPopup: PropTypes.func
};

export { SubscriptionAppealBanner };
